@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-inter: Inter;
  --font-dm-sans: "DM Sans";
  --font-darker-grotesque: "Darker Grotesque";
  --font-montserrat: Montserrat;
  --font-work-sans: "Work Sans";

  /* font sizes */
  --font-size-base-9: 0.994rem;
  --font-size-34xl-5: 3.344rem;
  --font-size-24xl: 2.688rem;
  --font-size-13xl: 2rem;
  --font-size-lg: 1.125rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-9xl-4: 1.775rem;
  --font-size-4xl: 1.438rem;
  --font-size-xs-4: 0.713rem;
  --font-size-21xl: 2.5rem;
  --font-size-5xl: 1.5rem;
  --font-size-mini: 0.938rem;
  --font-size-7xl-4: 1.65rem;
  --font-size-2xl: 1.313rem;
  --font-size-8xl: 1.688rem;
  --font-size-3xl: 1.375rem;
  --font-size-61xl: 5rem;
  --font-size-20xl: 2rem;
  --font-size-12xl: 1.938rem;
  --font-size-14xl: 2.063rem;
  --font-size-xl: 1.25rem;
  --font-size-7xl: 1.625rem;
  --font-size-9xl: 1.75rem;

  /* Colors */
  --color-black: #000;
  --color-orange: #ffb800;
  --color-blue: rgba(24, 24, 246, 0.01);
  --color-blueviolet-100: #6843ec;
  --primary: #4452fe;
  --color-blueviolet-200: rgba(74, 74, 255, 0.01);
  --color-aliceblue: rgba(239, 245, 255, 0.69);
  --color-gainsboro: #e5e7eb;
  --color-gainsboro-100: #d9d9d9;
  --color-greenyellow-100: #d2ff3a;
  --color-greenyellow-200: #ccff00;
  --color-greenyellow-300: #b3e600;
  --color-greenyellow-400: rgba(179, 230, 0, 0.09);
  --color-yellowgreen: #b8e621;
  --color-lightgray: #cccfd1;
  --color-silver: #c4c4c4;
  --light-text: #fff;
  --color-gray: #19161c;
  --color-darkslateblue: #3b3c7a;
  --color-mediumblue: #3d37f1;
  --color-mediumslateblue: #4469ff;

  /* Gaps */
  --gap-9xl-5: 1.781rem;
  --gap-98xl: 7.313rem;
  --gap-20xl: 2.438rem;
  --gap-43xl-5: 3.906rem;
  --gap-12xl: 1.938rem;
  --gap-base: 1rem;
  --gap-23xl-6: 2.663rem;
  --gap-2xl: 1.313rem;
  --gap-12xl-3: 1.956rem;
  --gap-3xl-8: 1.425rem;
  --gap-3xs-5: 0.594rem;
  --gap-smi-5: 0.781rem;
  --gap-3xl-7: 1.419rem;
  --gap-95xl: 7.125rem;
  --gap-38xl: 3.563rem;
  --gap-9xl: 1.75rem;
  --gap-44xl: 3.938rem;
  --gap-56xl: 4.688rem;
  --gap-18xl: 2.313rem;
  --gap-lgi: 1.188rem;
  --gap-smi: 0.813rem;
  --gap-39xl: 3.625rem;
  --gap-7xl: 1.625rem;
  --gap-22xl: 2.563rem;
  --gap-45xl: 4rem;
  --gap-13xl: 2rem;
  --gap-mid: 1.063rem;
  --gap-sm-2: 0.825rem;
  --gap-8xs-7: 0.294rem;
  --gap-19xl-3: 2.394rem;
  --gap-8xl: 1.688rem;
  --gap-23xl-5: 2.656rem;
  --gap-base-5: 1.031rem;
  --gap-xl: 1.25rem;
  --gap-5xs: 0.5rem;

  /* Paddings */
  --padding-xl: 1.25rem;
  --padding-268xl: 17.938rem;
  --padding-168xl: 11.688rem;
  --padding-103xl: 7.625rem;
  --padding-9xl: 1.75rem;
  --padding-37xl-6: 3.538rem;
  --padding-38xl: 3.563rem;
  --padding-smi: 0.813rem;
  --padding-5xs: 0.5rem;
  --padding-2xs: 0.688rem;
  --padding-2xl: 1.313rem;
  --padding-6xs: 0.438rem;
  --padding-lg: 1.125rem;
  --padding-10xl: 1.813rem;
  --padding-xs-3: 0.706rem;
  --padding-25xl: 2.75rem;
  --padding-lgi: 1.188rem;
  --padding-lgi-9: 1.244rem;
  --padding-3xl-7: 1.419rem;
  --padding-11xs-9: 0.119rem;
  --padding-3xl-5: 1.406rem;
  --padding-9xs: 0.25rem;
  --padding-12xs: 0.063rem;
  --padding-8xs: 0.313rem;
  --padding-7xs: 0.375rem;
  --padding-3xl: 1.375rem;
  --padding-4xs: 0.563rem;
  --padding-22xl: 2.563rem;
  --padding-8xl: 1.688rem;
  --padding-4xl: 1.438rem;
  --padding-mini: 0.938rem;
  --padding-5xl: 1.5rem;
  --padding-23xl: 2.625rem;
  --padding-58xl: 4.813rem;
  --padding-31xl: 3.125rem;
  --padding-28xl: 2.938rem;
  --padding-53xl: 4.5rem;
  --padding-12xl: 1.938rem;
  --padding-11xs: 0.125rem;
  --padding-11xl: 1.875rem;
  --padding-3xs: 0.625rem;
  --padding-10xs-6: 0.163rem;
  --padding-33xl: 3.25rem;
  --padding-17xl-8: 2.3rem;
  --padding-xs-6: 0.725rem;
  --padding-7xl: 1.625rem;
  --padding-8xs-7: 0.294rem;
  --padding-6xl: 1.563rem;
  --padding-10xl-2: 1.825rem;

  /* Border radiuses */
  --br-9980xl: 9999px;
  --br-31xl: 50px;
  --br-lgi: 19px;
  --br-5xl: 24px;
  --br-4981xl: 5000px;
  --br-4xl: 23px;
  --br-lg: 18px;
  --br-26xl: 45px;

  /* Effects */
  --shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
}
