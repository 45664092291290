.ecosystemBeautiful,
.keepingOur {
  margin: 0;
}
.keepingOurEcosystemContainer {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: 1.2px;
  line-height: 3.5rem;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.ecosystemIcon {
  height: 33.688rem;
  width: 59.313rem;
  position: absolute;
  margin: 0 !important;
  top: -0.562rem;
  right: -0.812rem;
  filter: blur(349px);
  border-radius: 50%;
  background-color: rgba(255, 184, 0, 0.32);
}
.imageIcon {
  width: 3rem;
  height: 3rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.enablingUsersTo,
.heading4 {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.heading4 {
  width: 13.831rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-weight: 600;
}
.enablingUsersTo {
  margin: 0;
  width: 14.619rem;
  height: 4.125rem;
  font-size: 0.938rem;
  letter-spacing: 0.4px;
  line-height: 1.375rem;
  color: rgba(255, 255, 255, 0.6);
}
.divecosystemItemM6U0 {
  width: 19.125rem;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 1.25rem 3.406rem 2rem;
  box-sizing: border-box;
  gap: 1rem;
}
.imageIcon1 {
  width: 3rem;
  height: 3rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.blockchainBasedAttendanceMa,
.heading41 {
  position: relative;
  display: flex;
  align-items: center;
}
.heading41 {
  width: 10.413rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-weight: 600;
  flex-shrink: 0;
}
.blockchainBasedAttendanceMa {
  margin: 0;
  width: 13.719rem;
  font-size: 0.938rem;
  letter-spacing: 0.4px;
  line-height: 1.375rem;
  color: rgba(255, 255, 255, 0.6);
}
.divecosystemItemM6U01 {
  width: 19.125rem;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 1.25rem 2.031rem 2rem;
  box-sizing: border-box;
  gap: 1rem;
}
.imageIcon2 {
  width: 3rem;
  height: 3rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.dMeetIsA,
.heading42 {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.heading42 {
  width: 9.838rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-weight: 600;
}
.dMeetIsA {
  margin: 0;
  width: 13.525rem;
  height: 5.5rem;
  font-size: 0.938rem;
  letter-spacing: 0.4px;
  line-height: 1.375rem;
  color: rgba(255, 255, 255, 0.6);
}
.divecosystemItemM6U02 {
  width: 19.125rem;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 1.25rem 3.281rem 2rem;
  box-sizing: border-box;
  gap: 1rem;
}
.imageIcon3 {
  width: 3rem;
  height: 3rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.facilitatingThePayment,
.heading43 {
  position: relative;
  display: flex;
  align-items: center;
}
.heading43 {
  width: 13.881rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-weight: 600;
  flex-shrink: 0;
}
.facilitatingThePayment {
  margin: 0;
  width: 13.694rem;
  font-size: 0.938rem;
  letter-spacing: 0.4px;
  line-height: 1.375rem;
  color: rgba(255, 255, 255, 0.6);
}
.divecosystemItemM6U03 {
  width: 19.125rem;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 1.25rem 2.031rem 2rem;
  box-sizing: border-box;
  gap: 1rem;
}
.imageIcon4 {
  width: 3rem;
  height: 3rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.heading44,
.nftHoldersCan {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.heading44 {
  width: 14.2rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-weight: 600;
}
.nftHoldersCan {
  margin: 0;
  width: 14.875rem;
  height: 5.5rem;
  font-size: 0.938rem;
  letter-spacing: 0.4px;
  line-height: 1.375rem;
  color: rgba(255, 255, 255, 0.6);
}
.divecosystemItemM6U04 {
  width: 19.125rem;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 1.25rem 3.406rem 2rem;
  box-sizing: border-box;
  gap: 1rem;
}
.imageIcon5 {
  width: 3rem;
  height: 3rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.enablesUsersTo,
.heading45 {
  position: relative;
  display: flex;
  align-items: center;
}
.heading45 {
  width: 9.625rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-weight: 600;
  flex-shrink: 0;
}
.enablesUsersTo {
  margin: 0;
  width: 14.5rem;
  font-size: 0.938rem;
  letter-spacing: 0.4px;
  line-height: 1.375rem;
  color: rgba(255, 255, 255, 0.6);
}
.divecosystemItemM6U05,
.divecosystemItemM6U0Parent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.divecosystemItemM6U05 {
  width: 19.125rem;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  flex-direction: column;
  padding: 2rem 1.25rem 2.031rem 2rem;
  box-sizing: border-box;
  gap: 1rem;
}
.divecosystemItemM6U0Parent {
  width: 58.5rem;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem 0.375rem;
  min-height: 33.125rem;
  max-width: 100%;
  z-index: 1;
}
@media screen and (max-width: 750px) {
  .divecosystemItemM6U0 {
    width: 90%;
  }
}
.ecosystemIconParent {
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  font-size: 1rem;
}
.ecosystemIconParent,
.keepingEcosystem,
.keepingEcosystemWrapper {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.keepingEcosystem {
  flex-direction: column;
  justify-content: flex-start;
  gap: 6.062rem;
}
.keepingEcosystemWrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 6.343rem;
  box-sizing: border-box;
  text-align: left;
  font-size: 3.25rem;
  color: #fff;
  font-family: Inter;
}
@media screen and (max-width: 1050px) {
  .keepingEcosystem {
    gap: 3rem;
  }
  .keepingEcosystemWrapper {
    padding-bottom: 4.125rem;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .keepingOurEcosystemContainer {
    font-size: 2.625rem;
    line-height: 2.813rem;
    margin: 20px;
  }
  .keepingEcosystem {
    gap: 1.5rem;
  }
}
@media screen and (max-width: 450px) {
  .keepingOurEcosystemContainer {
    font-size: 1.938rem;
    line-height: 2.125rem;
  }
  .keepingEcosystemWrapper {
    padding-bottom: 2.688rem;
    box-sizing: border-box;
  }
}
