._404,
._404 * {
  box-sizing: border-box;
}
._404 {
  background: #6843ec;
  height: 1400px;
  position: relative;
  overflow: hidden;
  top: -10vw;
  z-index: -1;
}
._404 .ellipse-32 {
  background: #6843ec;
  border-radius: 50%;
  width: 457px;
  height: 457px;
  position: absolute;
  left: -128px;
  top: -178px;
  filter: blur(174.5px);
}
._404 .ellipse-33 {
  background: #6843ec;
  border-radius: 50%;
  width: 457px;
  height: 457px;
  position: absolute;
  left: 1179px;
  top: 102px;
  filter: blur(174.5px);
}
._404 .frame-115 {
  display: flex;
  flex-direction: row;
  gap: 688px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 105px;
  top: 45px;
}
._404 .logo {
  color: #ffffff;
  text-align: left;
  font-family: "TurretRoad-Bold", sans-serif;
  font-size: 36px;
  font-weight: 700;
  position: relative;
}
._404 .frame {
  flex-shrink: 0;
  width: 467px;
  height: 27px;
  position: relative;
}
._404 .frame2 {
  height: 27px;
  position: absolute;
  right: 58.03%;
  left: 21.63%;
  width: 20.34%;
  top: 0px;
}
._404 .frame3 {
  width: 24px;
  height: 24px;
  position: absolute;
  left: calc(50% - -23.5px);
  top: 2px;
  overflow: hidden;
}
._404 .group {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
._404 .build {
  color: #ffffff;
  text-align: center;
  font-family: "WorkSans-Regular", sans-serif;
  font-size: 17px;
  line-height: 27.3px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - 18.19px);
  top: -1px;
}
._404 .frame4 {
  height: 27px;
  position: absolute;
  right: 0%;
  left: 68.52%;
  width: 31.48%;
  top: 0px;
}
._404 .frame5 {
  width: 24px;
  height: 24px;
  position: absolute;
  left: calc(50% - -49.5px);
  top: 2px;
  overflow: hidden;
}
._404 .group2 {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
._404 .community {
  color: #ffffff;
  text-align: center;
  font-family: "WorkSans-Regular", sans-serif;
  font-size: 17px;
  line-height: 27.3px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - 45.5px);
  top: -1px;
}
._404 .frame6 {
  height: 27px;
  position: absolute;
  right: 78.37%;
  left: 0%;
  width: 21.63%;
  top: 0px;
}
._404 .frame7 {
  width: 24px;
  height: 24px;
  position: absolute;
  left: calc(50% - -26.5px);
  top: 2px;
  overflow: hidden;
}
._404 .group3 {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
._404 .join-us {
  color: #ffffff;
  text-align: center;
  font-family: "WorkSans-Regular", sans-serif;
  font-size: 17px;
  line-height: 27.3px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - 26.5px);
  top: -1px;
}
._404 .frame8 {
  height: 27px;
  position: absolute;
  right: 31.48%;
  left: 41.97%;
  width: 26.55%;
  top: 0px;
}
._404 .frame9 {
  width: 24px;
  height: 24px;
  position: absolute;
  left: calc(50% - -38px);
  top: 2px;
  overflow: hidden;
}
._404 .group4 {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
._404 .product {
  color: #ffffff;
  text-align: center;
  font-family: "WorkSans-Regular", sans-serif;
  font-size: 18px;
  line-height: 27.3px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - 33.69px);
  top: -1px;
}
._404 .frame10 {
  height: 48px;
  position: absolute;
  right: 6.25%;
  left: 10.42%;
  width: 83.33%;
  top: 2357px;
}
._404 .frame11 {
  height: 422px;
  position: absolute;
  right: -413.06%;
  left: 249.73%;
  width: 263.33%;
  top: 78px;
}
._404 .frame12 {
  height: 200px;
  position: absolute;
  right: 90.5%;
  left: 0.01%;
  width: 9.49%;
  top: 1.22px;
  overflow: hidden;
}
._404 .rectangle {
  background: #19161c;
  border-radius: 8px;
  height: 200px;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  top: 0px;
}
._404 .frame13 {
  height: 200px;
  position: absolute;
  right: 0.09%;
  left: -0.09%;
  width: 100%;
  top: -0.22px;
}
._404 .solana-hacker-house-participants {
  color: #bababa;
  text-align: center;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 12px;
  line-height: 15.36px;
  font-weight: 400;
  text-transform: uppercase;
  position: absolute;
  left: calc(50% - 123.74px);
  top: calc(50% - -18.22px);
  width: 248px;
}
._404 ._11-000 {
  color: #f087ff;
  text-align: center;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 37px;
  line-height: 46.2px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - 58.74px);
  top: calc(50% - 37.78px);
  width: 117px;
}
._404 .frame14 {
  height: 200px;
  position: absolute;
  right: 90.5%;
  left: 0.01%;
  width: 9.49%;
  top: 221.22px;
  overflow: hidden;
}
._404 .frame15 {
  height: 200px;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  top: 0px;
}
._404 .rectangle2 {
  background: rgba(0, 0, 0, 0.6);
  height: 200px;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  top: 0px;
}
._404 .frame16 {
  height: 159px;
  position: absolute;
  right: 10.09%;
  left: 9.91%;
  width: 80%;
  bottom: 30.22px;
}
._404
  .users-developers-and-reporters-were-on-the-scene-when-solana-labs-announced-the-solana-mobile-stack-and-saga-device-in-june-2022 {
  color: #ffffff;
  text-align: left;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 16px;
  line-height: 17.6px;
  font-weight: 400;
  position: absolute;
  right: 1.97%;
  left: 0.11%;
  width: 97.92%;
  top: 72.22px;
}
._404 .solana-mobile-announcement {
  color: #ffffff;
  text-align: left;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 21px;
  line-height: 28.08px;
  font-weight: 400;
  position: absolute;
  right: 39.47%;
  left: 0.11%;
  width: 60.42%;
  top: 1.22px;
}
._404 .image {
  height: 200px;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  top: 0px;
  object-fit: cover;
}
._404 .frame17 {
  height: 420px;
  position: absolute;
  right: 70.88%;
  left: 10.13%;
  width: 18.99%;
  top: 1.22px;
  overflow: hidden;
}
._404 .rectangle3 {
  background: #19161c;
  border-radius: 8px;
  height: 420px;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  top: 0px;
}
._404 .frame18 {
  height: 420px;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  top: 0px;
}
._404 .rectangle4 {
  background: rgba(0, 0, 0, 0.6);
  height: 420px;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  top: 0px;
}
._404 .frame19 {
  height: 132px;
  position: absolute;
  right: 5.04%;
  left: 4.96%;
  width: 90%;
  bottom: 30.22px;
}
._404 .frame20 {
  width: 185px;
  height: 65px;
  position: absolute;
  left: calc(50% - 269.74px);
  top: 0.22px;
  overflow: hidden;
}
._404 .vector {
  height: auto;
  position: absolute;
  right: 32.8%;
  left: 36.22%;
  width: 30.99%;
  bottom: 8.22%;
  top: 0%;
  height: 91.78%;
  overflow: visible;
}
._404 .vector2 {
  height: auto;
  position: absolute;
  right: 30.26%;
  left: 58.76%;
  width: 10.98%;
  bottom: 22.95%;
  top: 46.99%;
  height: 30.06%;
  overflow: visible;
}
._404 .vector3 {
  height: auto;
  position: absolute;
  right: 32.3%;
  left: 60.81%;
  width: 6.89%;
  bottom: 28.77%;
  top: 52.81%;
  height: 18.42%;
  overflow: visible;
}
._404 .vector4 {
  height: auto;
  position: absolute;
  right: 26.52%;
  left: 71.1%;
  width: 2.38%;
  bottom: 22.94%;
  top: 46.99%;
  height: 30.07%;
  overflow: visible;
}
._404 .vector5 {
  height: auto;
  position: absolute;
  right: 14.2%;
  left: 74.81%;
  width: 10.99%;
  bottom: 22.94%;
  top: 46.99%;
  height: 30.07%;
  overflow: visible;
}
._404 .vector6 {
  height: auto;
  position: absolute;
  right: 2.32%;
  left: 87.12%;
  width: 10.56%;
  bottom: 22.94%;
  top: 46.99%;
  height: 30.07%;
  overflow: visible;
}
._404 .vector7 {
  height: auto;
  position: absolute;
  right: 87.03%;
  left: 2.32%;
  width: 10.65%;
  bottom: 58.46%;
  top: 12.42%;
  height: 29.11%;
  overflow: visible;
}
._404 .vector8 {
  height: auto;
  position: absolute;
  right: 75.22%;
  left: 14.7%;
  width: 10.08%;
  bottom: 58.46%;
  top: 12.42%;
  height: 29.11%;
  overflow: visible;
}
._404 .vector9 {
  height: auto;
  position: absolute;
  right: 63.55%;
  left: 26.43%;
  width: 10.02%;
  bottom: 58.46%;
  top: 12.42%;
  height: 29.11%;
  overflow: visible;
}
._404 .vector10 {
  height: auto;
  position: absolute;
  right: 51.1%;
  left: 38.25%;
  width: 10.65%;
  bottom: 58.46%;
  top: 12.42%;
  height: 29.11%;
  overflow: visible;
}
._404 .group5 {
  height: auto;
  position: absolute;
  right: 6.5%;
  left: 74.81%;
  width: 18.69%;
  bottom: 0%;
  top: 86.67%;
  height: 13.33%;
  overflow: visible;
}
._404
  ._3-800-builders-3-days-1-community-teams-from-around-the-world-gathered-in-lisbon-in-nov-2022-to-learn-build-and-celebrate-at-the-annual-conference-from-the-solana-foundation {
  color: #ffffff;
  text-align: left;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 16px;
  line-height: 17.6px;
  font-weight: 400;
  position: absolute;
  right: 0.32%;
  left: 0.05%;
  width: 99.63%;
  top: 79.22px;
}
._404 .image2 {
  height: 420px;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  top: 0px;
  object-fit: cover;
}
._404 .frame21 {
  height: 200px;
  position: absolute;
  right: 60.75%;
  left: 29.76%;
  width: 9.49%;
  top: 1.22px;
  overflow: hidden;
}
._404 .frame22 {
  height: 113px;
  position: absolute;
  right: 10.09%;
  left: 9.91%;
  width: 80%;
  bottom: 30.22px;
}
._404 .new-york-hacker-house {
  color: #ffffff;
  text-align: left;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 20px;
  line-height: 28.08px;
  font-weight: 400;
  position: absolute;
  right: 6.56%;
  left: 0.11%;
  width: 93.33%;
  top: 0.22px;
}
._404
  .start-spreading-the-news-lines-were-out-the-door-at-the-solana-foundation-s-new-york-hacker-house-in-march-2022 {
  color: #ffffff;
  text-align: left;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 16px;
  line-height: 17.6px;
  font-weight: 400;
  position: absolute;
  right: 5.72%;
  left: 0.11%;
  width: 94.17%;
  top: 43.22px;
}
._404 .frame23 {
  height: 200px;
  position: absolute;
  right: 60.75%;
  left: 29.76%;
  width: 9.49%;
  top: 221.22px;
  overflow: hidden;
}
._404 .frame24 {
  width: 268px;
  height: 168px;
  position: absolute;
  left: calc(50% - 134.26px);
  top: calc(50% - 84.22px);
}
._404 ._48-000 {
  color: #19fb9b;
  text-align: center;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 42px;
  line-height: 46.2px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - 68.74px);
  top: calc(50% - 44.78px);
  width: 137px;
}
._404 .developers-building-during-solana-hackathons {
  color: #bababa;
  text-align: center;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 12px;
  line-height: 15.36px;
  font-weight: 400;
  text-transform: uppercase;
  position: absolute;
  left: calc(50% - 97.74px);
  top: calc(50% - -11.22px);
  width: 195px;
}
._404 .frame25 {
  height: 420px;
  position: absolute;
  right: 50.62%;
  left: 39.88%;
  width: 9.49%;
  top: 1.22px;
  overflow: hidden;
}
._404 .seoul-hacker-house {
  color: #ffffff;
  text-align: left;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 20px;
  line-height: 28.08px;
  font-weight: 400;
  position: absolute;
  right: 21.14%;
  left: 0.11%;
  width: 78.75%;
  top: 0.22px;
}
._404
  .let-s-build-local-devs-came-to-the-solana-foundation-s-seoul-hacker-house-ahead-of-korea-blockchain-week-2022 {
  color: #ffffff;
  text-align: left;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 16px;
  line-height: 17.6px;
  font-weight: 400;
  position: absolute;
  right: 8.64%;
  left: 0.11%;
  width: 91.25%;
  top: 43.22px;
}
._404 .frame26 {
  height: 200px;
  position: absolute;
  right: 40.5%;
  left: 50.01%;
  width: 9.49%;
  top: 1.22px;
  overflow: hidden;
}
._404 ._3-800 {
  color: #ffeb3b;
  text-align: center;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 42px;
  line-height: 46.2px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - 55.74px);
  top: calc(50% - 37.78px);
  width: 111px;
}
._404 .solana-breakpoint-2022-attendees {
  color: #bababa;
  text-align: center;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 12px;
  line-height: 15.36px;
  font-weight: 400;
  text-transform: uppercase;
  position: absolute;
  left: calc(50% - 123.74px);
  top: calc(50% - -18.22px);
  width: 247px;
}
._404 .frame27 {
  height: 200px;
  position: absolute;
  right: 40.5%;
  left: 50.01%;
  width: 9.49%;
  top: 221.22px;
  overflow: hidden;
}
._404 .frame28 {
  height: 130px;
  position: absolute;
  right: 10.09%;
  left: 9.91%;
  width: 80%;
  bottom: 30.22px;
}
._404 .london-hacker-house {
  color: #ffffff;
  text-align: left;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 20px;
  line-height: 28.08px;
  font-weight: 400;
  position: absolute;
  right: 13.64%;
  left: 0.11%;
  width: 86.25%;
  top: 0.22px;
}
._404
  .builders-joined-london-hacker-house-a-five-day-offline-event-in-june-2022-with-advice-and-support-from-core-solana-lab-engineers {
  color: #ffffff;
  text-align: left;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 16px;
  line-height: 17.6px;
  font-weight: 400;
  position: absolute;
  right: 2.39%;
  left: 0.11%;
  width: 97.5%;
  top: 43.22px;
}
._404 .frame29 {
  height: 420px;
  position: absolute;
  right: 20.88%;
  left: 60.13%;
  width: 18.99%;
  top: 1.22px;
  overflow: hidden;
}
._404 .frame30 {
  height: 79px;
  position: absolute;
  right: 5.04%;
  left: 4.96%;
  width: 90%;
  bottom: 30.22px;
}
._404 .seoul-hacker-house2 {
  color: #ffffff;
  text-align: left;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 20px;
  line-height: 28.08px;
  font-weight: 400;
  position: absolute;
  right: 64.95%;
  left: 0.05%;
  width: 35%;
  top: 0.22px;
}
._404
  .let-s-build-local-devs-came-to-the-solana-foundation-s-seoul-hacker-house-ahead-of-korea-blockchain-week-20222 {
  color: #ffffff;
  text-align: left;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 16px;
  line-height: 17.6px;
  font-weight: 400;
  position: absolute;
  right: 8.47%;
  left: 0.05%;
  width: 91.48%;
  top: 43.22px;
}
._404 .frame31 {
  height: 200px;
  position: absolute;
  right: 10.75%;
  left: 79.76%;
  width: 9.49%;
  top: 1.22px;
  overflow: hidden;
}
._404 .new-delhi-hacker-house {
  color: #ffffff;
  text-align: left;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 20px;
  line-height: 28.08px;
  font-weight: 400;
  position: absolute;
  right: 3.22%;
  left: 0.11%;
  width: 96.67%;
  top: 0.22px;
}
._404
  .vibe-mint-build-thousands-of-developers-came-to-build-together-at-the-sept-2022-new-delhi-solana-hacker-house {
  color: #ffffff;
  text-align: left;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 16px;
  line-height: 17.6px;
  font-weight: 400;
  position: absolute;
  right: 6.14%;
  left: 0.11%;
  width: 93.75%;
  top: 43.22px;
}
._404 .frame32 {
  height: 200px;
  position: absolute;
  right: 10.75%;
  left: 79.76%;
  width: 9.49%;
  top: 221.22px;
  overflow: hidden;
}
._404 .frame33 {
  width: 181px;
  height: 168px;
  position: absolute;
  left: calc(50% - 90.76px);
  top: calc(50% - 84.22px);
}
._404 ._1-075 {
  color: #00bcd4;
  text-align: center;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 36px;
  line-height: 46.2px;
  font-weight: 400;
  position: absolute;
  left: calc(50% - 47.74px);
  top: calc(50% - 37.78px);
  width: 96px;
}
._404 .daily-programs-used {
  color: #bababa;
  text-align: center;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 12px;
  line-height: 15.36px;
  font-weight: 400;
  text-transform: uppercase;
  position: absolute;
  left: calc(50% - 74.74px);
  top: calc(50% - -18.22px);
  width: 149px;
}
._404 .frame34 {
  height: 420px;
  position: absolute;
  right: 0.62%;
  left: 89.88%;
  width: 9.49%;
  top: 1.22px;
  overflow: hidden;
}
._404 .solana-miami {
  color: #ffffff;
  text-align: left;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 21px;
  line-height: 28.08px;
  font-weight: 400;
  position: absolute;
  right: 46.14%;
  left: 0.11%;
  width: 53.75%;
  top: 0.22px;
}
._404
  .new-and-native-users-came-to-solana-miami-in-april-2022-to-build-learn-and-see-real-world-solana-use-cases {
  color: #ffffff;
  text-align: left;
  font-family: "Helvetica-Regular", sans-serif;
  font-size: 16px;
  line-height: 17.6px;
  font-weight: 400;
  position: absolute;
  right: 8.64%;
  left: 0.11%;
  width: 91.25%;
  top: 43.22px;
}
._404 .div-footer {
  width: 1443px;
  height: 383px;
  position: absolute;
  left: calc(50% - 723px);
  top: 1126px;
}
._404 .rectangle5 {
  background: #0f0f0f;
  border-radius: 12px 12px 0px 0px;
  border-style: solid;
  border-color: #141414;
  border-width: 1px;
  height: 383px;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  top: 0px;
}
._404 .frame35 {
  width: 278.27px;
  height: 221px;
  position: absolute;
  left: 172px;
  top: calc(50% - 84.5px);
}
._404 .frame-118 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 13px;
  top: -3px;
}
._404 .c-2-c {
  color: #ffffff;
  text-align: left;
  font-family: "TurretRoad-Bold", sans-serif;
  font-size: 36px;
  font-weight: 700;
  position: relative;
}
._404 .frame-117 {
  display: flex;
  flex-direction: column;
  gap: 9px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
._404 .managed-by-ethereum-technology {
  color: #ffffff;
  text-align: left;
  font-family: "WorkSans-Regular", sans-serif;
  font-size: 16px;
  line-height: 27.3px;
  font-weight: 400;
  position: relative;
}
._404 .frame36 {
  flex-shrink: 0;
  width: 206px;
  height: 30px;
  position: relative;
}
._404 .frame37 {
  border-radius: 100px;
  height: 16px;
  position: absolute;
  right: 87.38%;
  left: 0%;
  width: 12.62%;
  top: 4.58px;
}
._404 .rectangle6 {
  background: #848895;
  height: 16px;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  top: 0px;
}
._404 .frame38 {
  border-radius: 100px;
  width: 16px;
  height: 16px;
  position: absolute;
  left: calc(50% - 8px);
  top: 0px;
  overflow: visible;
}
._404 .frame39 {
  background: #0f0f0f;
  height: 16px;
  position: absolute;
  right: 52.43%;
  left: 34.95%;
  width: 12.62%;
  top: 4.58px;
}
._404 .frame40 {
  width: 16px;
  height: 16px;
  position: absolute;
  left: calc(50% - 8px);
  top: 0px;
  overflow: visible;
}
._404 .frame41 {
  height: 16px;
  position: absolute;
  right: 17.48%;
  left: 69.9%;
  width: 12.62%;
  top: 4.58px;
}
._404 .frame42 {
  width: 16px;
  height: 16px;
  position: absolute;
  left: calc(50% - 8px);
  top: 0px;
  overflow: visible;
}
._404 .frame43 {
  height: 16px;
  position: absolute;
  right: 69.9%;
  left: 17.48%;
  width: 12.62%;
  top: 4.58px;
}
._404 .frame44 {
  width: 16px;
  height: 16px;
  position: absolute;
  left: calc(50% - 8px);
  top: 0px;
  overflow: visible;
}
._404 .frame45 {
  background: #ffffff;
  border-radius: 100px;
  height: 16px;
  position: absolute;
  right: 0%;
  left: 87.38%;
  width: 12.62%;
  top: 4.58px;
}
._404 .frame46 {
  width: 16px;
  height: 16px;
  position: absolute;
  left: calc(50% - 8px);
  top: 0px;
  overflow: visible;
}
._404 .frame47 {
  background: #ffffff;
  border-radius: 100px;
  height: 16px;
  position: absolute;
  right: 34.95%;
  left: 52.43%;
  width: 12.62%;
  top: 4.58px;
  overflow: hidden;
}
._404 .frame48 {
  width: 16px;
  height: 16px;
  position: absolute;
  left: calc(50% - 8px);
  top: 0px;
  overflow: visible;
}
._404 .frame-116 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  right: 161px;
  top: 98px;
}
._404 .frame49 {
  flex-shrink: 0;
  width: 161px;
  height: 221px;
  position: relative;
}
._404 .frame50 {
  height: 195px;
  position: absolute;
  right: 29.81%;
  left: 12.42%;
  width: 57.76%;
  top: 26px;
}
._404 .grants {
  color: #848895;
  text-align: left;
  font-family: "WorkSans-Regular", sans-serif;
  font-size: 15px;
  line-height: 27.3px;
  font-weight: 400;
  position: absolute;
  right: 46.82%;
  left: 0.49%;
  width: 52.69%;
  top: 5.22px;
}
._404 .generator {
  color: #848895;
  text-align: left;
  font-family: "WorkSans-Regular", sans-serif;
  font-size: 16px;
  line-height: 27.3px;
  font-weight: 400;
  position: absolute;
  right: 16.13%;
  left: 0%;
  width: 83.87%;
  top: 45px;
}
._404 .disclaimer {
  color: #848895;
  text-align: left;
  font-family: "WorkSans-Regular", sans-serif;
  font-size: 16px;
  line-height: 27.3px;
  font-weight: 400;
  position: absolute;
  right: 10.75%;
  left: 0%;
  width: 89.25%;
  top: 123px;
}
._404 .careers {
  color: #848895;
  text-align: left;
  font-family: "WorkSans-Regular", sans-serif;
  font-size: 16px;
  line-height: 27.3px;
  font-weight: 400;
  position: absolute;
  right: 35.48%;
  left: 0%;
  width: 64.52%;
  top: 84px;
}
._404 .ether {
  color: #ffffff;
  text-align: left;
  font-family: "DarkerGrotesque-Bold", sans-serif;
  font-size: 16px;
  line-height: 18.72px;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  right: 46.92%;
  left: 12.7%;
  width: 40.37%;
  top: -0.78px;
}
._404 .frame51 {
  flex-shrink: 0;
  width: 190px;
  height: 221px;
  position: relative;
}
._404 .frame52 {
  height: 221px;
  position: absolute;
  right: 35.01%;
  left: 0%;
  width: 64.99%;
  top: 0px;
}
._404 .frame53 {
  height: 117px;
  position: absolute;
  right: 23.41%;
  left: 9.76%;
  width: 66.83%;
  top: 26px;
}
._404 .services {
  color: #848895;
  text-align: left;
  font-family: "WorkSans-Regular", sans-serif;
  font-size: 16px;
  line-height: 27.3px;
  font-weight: 400;
  position: absolute;
  right: 19.68%;
  left: 0.33%;
  width: 79.98%;
  top: 5.22px;
}
._404 .newsletter {
  color: #848895;
  text-align: left;
  font-family: "WorkSans-Regular", sans-serif;
  font-size: 16px;
  line-height: 27.3px;
  font-weight: 400;
  position: absolute;
  right: -3.34%;
  left: 0.33%;
  width: 103.01%;
  top: 83.22px;
}
._404 .blog {
  color: #848895;
  text-align: left;
  font-family: "WorkSans-Regular", sans-serif;
  font-size: 15px;
  line-height: 27.3px;
  font-weight: 400;
  position: absolute;
  right: 60.89%;
  left: 0.33%;
  width: 38.78%;
  top: 44.22px;
}
._404 .get-connected {
  color: #ffffff;
  text-align: left;
  font-family: "DarkerGrotesque-Bold", sans-serif;
  font-size: 15px;
  line-height: 18.72px;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  right: 5.79%;
  left: 9.98%;
  width: 84.23%;
  top: -0.78px;
}
._404 .group-1171275443 {
  position: absolute;
  inset: 0;
}
._404 ._20 {
  width: 590.2px;
  height: 539.84px;
  position: absolute;
  right: 5.33%;
  left: 53.68%;
  width: 40.99%;
  bottom: 49.98%;
  top: 14.25%;
  height: 35.77%;
  overflow: visible;
}
._404 .oops-page-not-found {
  color: #222222;
  text-align: left;
  font-family: "EuclidCircularB-Regular", sans-serif;
  font-size: 35px;
  line-height: 34px;
  font-weight: 400;
  position: absolute;
  right: 59.24%;
  left: 14.37%;
  width: 26.39%;
  bottom: 63.29%;
  top: 34.46%;
  height: 2.25%;
}
._404 ._4042 {
  color: #222222;
  text-align: left;
  font-family: "EuclidCircularB-Regular", sans-serif;
  font-size: 200px;
  line-height: 220px;
  font-weight: 400;
  position: absolute;
  right: 59.51%;
  left: 14.65%;
  width: 25.83%;
  bottom: 67.86%;
  top: 17.56%;
  height: 14.58%;
}
._404
  ._404-error-on-the-servers-of-the-page-you-expect-to-reach-on-a-website-http-status-code-that-means-it-could-not-be-found {
  color: #222222;
  text-align: center;
  font-family: "EuclidCircularB-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  position: absolute;
  right: 54.37%;
  left: 9.44%;
  width: 36.18%;
  bottom: 58.98%;
  top: 37.84%;
  height: 3.18%;
}
._404 .buttons-instance {
  position: absolute !important;
  left: 297px !important;
  top: 676px !important;
}