body{
  background-color: black;
}
body{
  overflow-x: hidden;
}
body::-webkit-scrollbar{
  width: 4px;
  background-color: #000000;
}
body::-webkit-scrollbar-thumb{
  width: 1px;
  background-color: #d2ff3a;
}