.heading3,
.heading3YoureInGoodWrapper {
  display: flex;
  justify-content: center;
  max-width: 100%;
}
.heading3 {
  margin: 0;
  width: 23.269rem;
  position: relative;
  font-size: inherit;
  letter-spacing: 1.2px;
  line-height: 3.5rem;
  font-weight: 600;
  font-family: inherit;
  align-items: center;
  flex-shrink: 0;
}
.heading3YoureInGoodWrapper {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  padding: 0 1.25rem 0 1.312rem;
  box-sizing: border-box;
}
.itsStillEarly,
.soYouKnow {
  margin: 0;
}
.itsStillEarlyContainer {
  position: relative;
  font-size: 1.25rem;
  letter-spacing: 0.3px;
  line-height: 1.75rem;
  color: rgba(255, 255, 255, 0.4);
}
.frameGroup,
.frameWrapper {
  display: flex;
  max-width: 100%;
}
.frameGroup {
  width: 33.513rem;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 1.531rem;
}
.frameWrapper {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 1.25rem 0.937rem;
  box-sizing: border-box;
  text-align: center;
}
.frameDiv,
.frameParent1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.frameParent1 {
  align-self: stretch;
  gap: 0.437rem;
}
.frameDiv {
  width: 15.125rem;
  padding: 0.062rem 0 0;
  box-sizing: border-box;
}
@media screen and (max-width: 750px) {
  .frameDiv {
    width: 18.125rem;
  }
}
.frameChild {
  align-self: stretch;
  width: 18.625rem;
  position: relative;
  /* background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0),
    rgba(96, 92, 92, 0.05) 24%,
    rgba(74, 72, 72, 0.1) 46%,
    rgba(55, 53, 53, 0.3) 74%,
    rgba(0, 0, 0, 0.5)
  ); */
  /* border: 1px solid #000; */
  width: fit-content;
  border-radius: 13px;
  box-sizing: border-box;
  display: none;
}
.linkAdd509a1cb6566ab41f07d1 {
  width: 1rem;
  height: 0.875rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  top: 12rem;
}
.linkAdd509a1cb6566ab41f07d1Wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.062rem 0 0;
}
.link {
  position: relative;
  line-height: 1rem;
  font-weight: 500;
  display: inline-block;
  min-width: 5.25rem;
  top: 12rem;
}
.divtestimonialsTestimonial,
.rectangleParent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.divtestimonialsTestimonial {
  align-self: stretch;
  flex: 1;
  border-radius: 12px;
  align-items: center;
  padding: 1.875rem 2.812rem;
  gap: 0.625rem;
  background-image: url(https://campustocrypto.s3.ap-south-1.amazonaws.com/source/AccountAbstract.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}
@media screen and (max-width: 800px) {
  .divtestimonialsTestimonial {
    width: 18rem;
  }
}
.rectangleParent {
  height: 28.875rem;
  width: 18.625rem;
  /* background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0),
    rgba(96, 92, 92, 0.05) 24%,
    rgba(74, 72, 72, 0.1) 46%,
    rgba(55, 53, 53, 0.3) 74%,
    rgba(0, 0, 0, 0.5)
  ); */
  /* border: 1px solid #000; */
  /* width: fit-content; */
  border-radius: 13px;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 0.075rem 0 0 0.187rem;
}
.divtestimonialsTestimonialIcon,
.frameItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.frameItem {
  margin: 0 !important;
  right: 0;
  bottom: 0;
  /* background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0),
    rgba(96, 92, 92, 0.1) 20%,
    rgba(74, 72, 72, 0.2) 40%,
    rgba(65, 62, 62, 0.3) 59.6%,
    rgba(55, 53, 53, 0.4) 80%,
    rgba(0, 0, 0, 0.6)
  ); */
  /* border: 1px solid #000; */
  width: fit-content;
  border-radius: 13px;
  box-sizing: border-box;
  z-index: 1;
}
.divtestimonialsTestimonialIcon {
  border-radius: 12px;
  object-fit: cover;
}
.linkAdd509a1cb6566ab41f07d11 {
  position: absolute;
  top: 11.775rem;
  left: 9.113rem;
  width: 1.131rem;
  height: 0.875rem;
  overflow: hidden;
  z-index: 2;
}
@media screen and (max-width: 800px) {
  .linkAdd509a1cb6566ab41f07d11 {
    /* top: 11.775rem; */
    left: 6.113rem;
  }
}
.divtestimonialsTestimonialParent {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  right: 0.088rem;
  bottom: 0;
}
.link1 {
  width: 5.956rem;
  position: relative;
  line-height: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  z-index: 2;
  color: black;
}
.frameWrapper2,
.rectangleGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100%;
}
.rectangleGroup {
  flex: 1;
  flex-direction: column;
  padding: 11.75rem 1.25rem 1.437rem 5.25rem;
  position: relative;
}
.frameWrapper2 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0.062rem 0 0;
}
@media screen and (max-width: 800px) {
  .frameWrapper2 {
    align-self: center;
    width: 18rem;
  }
}
.frameParent2,
.frameParent3,
.frameWrapper3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.frameWrapper3 {
  flex: 1;
  flex-direction: column;
  padding: 0.062rem 0 0;
  box-sizing: border-box;
  min-width: 9.813rem;
}
.frameParent2,
.frameParent3 {
  align-self: stretch;
  flex-direction: row;
  gap: 0.437rem;
}
.frameParent2 {
  flex-direction: column;
  align-items: flex-end;
  max-width: 100%;
}
@media screen and (max-width: 800px) {
  /* .frameParent2 {
    max-width: 80%;
  } */
  .frameWrapper3 {
    min-width: 100%;
  }
}

.frameWrapper1 {
  /* flex: 1; */
  flex-direction: column;
  justify-content: center;
  padding: 0.062rem 0 0;
  box-sizing: border-box;
  /* min-width: 20.125rem; */
}
.frameContainer,
.frameWrapper1 {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.frameContainer {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.437rem;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.6);
}
.linkJoin {
  position: relative;
  letter-spacing: 0.3px;
  line-height: 1.063rem;
  font-weight: 500;
}
.groupIcon {
  width: 0.769rem;
  height: 0.563rem;
  position: relative;
}
.groupWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 0.187rem;
}
.linkJoinTheCommunityParent {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 0.312rem;
}
.frameWrapper4 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 1.5rem 0 1.25rem;
  font-size: 1rem;
}
.blockchainfinalInner,
.frameParent {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.frameParent {
  width: 65.625rem;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4rem;
}
.blockchainfinalInner {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 5.062rem;
  box-sizing: border-box;
  text-align: left;
  font-size: 3.25rem;
  color: #fff;
  font-family: Inter;
}
@media screen and (max-width: 1050px) {
  .frameParent {
    gap: 2rem;
  }
  .blockchainfinalInner {
    padding-bottom: 3.313rem;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .heading3 {
    font-size: 2.625rem;
    line-height: 2.813rem;
  }
  .frameParent3 {
    flex-wrap: wrap;
  }
  .frameParent {
    gap: 1rem;
  }
}
@media screen and (max-width: 450px) {
  .heading3 {
    font-size: 1.938rem;
    line-height: 2.125rem;
  }
  .itsStillEarlyContainer {
    font-size: 1rem;
    line-height: 1.375rem;
  }
  .blockchainfinalInner {
    padding-bottom: 2.125rem;
    box-sizing: border-box;
  }
}
