.main-right-side{
    display: flex;
    gap: 2.5vw;
}

.footer-section-content{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    color: white;
    justify-content: space-between;
    /* background-color: rgba(21, 21, 21, 0.86); */
    background-color:  #1E1E1E;
    padding: 4vw 14.5vw;
}
.footer-section-left{
    display: flex;
    flex-direction: column;
    gap: 1vw;
}
.footer-section-left img{
    width: 3vw;
}
.social-media-logo{
    display: flex;
    gap: 1vw;
}
.social-media-logo svg{
    font-size: 1.1vw;
    padding: 0.3vw 0.4vw;
    border-radius: 1vw;
    color: black;
    background-color: #848895;
}
.social-media-logo svg:hover{
    color: black;
    background-color: white;
    transform: scale(1.2);
    transition: all 0.1s ease-in-out;
}
.footer-section-right{
    color: white;
}
.footer-section-right ul li{
    list-style-type: none;
    padding-top: 0.7vw;

}
.footer-section-right ul{
    padding-left: 0vw;
    
}
.footer-section-right a{
    text-decoration: none;
    color: #848895;
    

}
.footer-section-right a:hover{
    color: white;
    transform: scale(1.1);
}

@media screen and (max-width: 720px) {
    /*logo*/
    
    
    .footer-section-left{
        
        margin-left:-35px;
        margin-top: -20px;
    }
    .Manged-by{
        font-size: 2.5vw;
        margin-top: -20px;
        margin-bottom: 4px;
        text-transform: uppercase;
    }
    .social-media-logo svg{
        /*size*/
        font-size: 2.9vw;
        padding: 0.5vw 0.5vw;
        border-radius: 2vw;

        /* adding space between logos*/
        margin-top: 8px;
        margin-right: 10px;


    }
    .footer-cp{
        font-size: 2.5vw;
        margin-top: 10px;
        
        
    }
    .footer-section-right{
        margin-right: -15px;
    }
    .footer-section-right ul li{
        /*size*/
        font-size: 2.5vw;
        padding-top: 1.5vw;

    }
    .get-connect{
        font-size: 2.5vw;
        margin-top: 10px;
        margin-bottom: 4px;
        text-transform: uppercase;

       
        
    }
    .navlin-img{
        width: 10vw !important;
        margin-top: 8px;
        margin-bottom: 20px;
    }
}