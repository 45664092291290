.buttons-type-404-page-state-default,
.buttons-type-404-page-state-default * {
  box-sizing: border-box;
}
div#root{
  height: 0px;
}
.buttons-type-404-page-state-default {
  width: 200px;
  height: 52px;
  position: relative;
}
.buttons-type-404-page-state-default .rectangle-1 {
  background: #222222;
  border-radius: 8px;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
}
.buttons-type-404-page-state-default .go-home {
  color: var(--commulink-purple, #6f58fa);
  text-align: left;
  font-family: "EuclidCircularB-Medium", sans-serif;
  font-size: 24px;
  font-weight: 500;
  position: absolute;
  right: 23.5%;
  left: 23.5%;
  width: 53%;
  bottom: 21.15%;
  top: 21.15%;
  height: 57.69%;
}
