.frame-child {
  height: 28.563rem;
  width: 28.563rem;
  top: -21.5rem;
  left: -13.437rem;
  border-radius: 50%;
  background-color: var(--color-blueviolet-100);
  filter: blur(349px);
}
.div,
.frame-child,
.frame-item {
  position: absolute;
  margin: 0 !important;
}
.frame-item {
  height: 28.563rem;
  width: 28.563rem;
  top: -4rem;
  right: -14.875rem;
  border-radius: 50%;
  background-color: #e84142;
  filter: blur(349px);
}
.div {
  width: 59.813rem;
  height: 36rem;
  bottom: -8.85rem;
  left: calc(50% - 478.5px);
  background: radial-gradient(
    50% 50%at 50% 50%,
    #4452fe,
    rgba(228, 115, 32, 0)
  );
  filter: blur(264px);
  mix-blend-mode: normal;
}
.figure-rounded-11px-icon {
  width: 82.375rem;
  height: 27rem;
  position: relative;
  border-radius: var(--br-4xl);
  object-fit: cover;
  display: none;
  mix-blend-mode: normal;
  max-width: 100%;
  z-index: 1;
}
.frame-inner {
  height: 9.725rem;
  width: 9.563rem;
  position: absolute;
  margin: 0 !important;
  top: -3.437rem;
  left: -5.875rem;
  object-fit: contain;
  z-index: 1;
  filter: blur(3px);
}
.chainlink {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.2px;
  line-height: 5.5rem;
  font-weight: 600;
  font-family: Inter;
  z-index: 2;
}
.frame-wrapper,
.rectangle-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.rectangle-parent {
  justify-content: flex-start;
  position: relative;
}
.frame-wrapper {
  width: 38.5rem;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.rectangle-icon {
  height: 6.331rem;
  width: 6.375rem;
  position: absolute;
  margin: 0 !important;
  top: -5.956rem;
  right: -6.025rem;
  object-fit: contain;
  z-index: 1;
  filter: blur(3px);
}
.bootcamp-2024 {
  position: relative;
  letter-spacing: 0.2px;
  line-height: 5.5rem;
  z-index: 2;
  font-family: Inter;
}
.ipsum-is-simply-dummy {
  position: relative;
  line-height: 1.75rem;
  z-index: 2;
  font-family: Inter;
}
.ipsum-is-simply-dummy-text-of-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  padding: 0 0 0 0.162rem;
  margin-top: -0.169rem;
  font-size: var(--font-size-base);
  color: var(--color-silver);
  font-family: var(--font-work-sans);
}
.bootcamp-2024-parent,
.rectangle-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.bootcamp-2024-parent {
  flex: 1;
  flex-direction: column;
}
.rectangle-group {
  align-self: stretch;
  flex-direction: row;
  position: relative;
}
.ipsum-is-simply-dummy1 {
  position: relative;
  line-height: 1.75rem;
  z-index: 1;
  text-align: center;
}
.ipsum-is-simply-dummy-text-of-container {
  width: 37.188rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  margin-top: -0.35rem;
  font-size: var(--font-size-base);
  color: var(--color-silver);
  font-family: var(--font-work-sans);
}
.frame-group,
.frame-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.frame-group {
  align-self: stretch;
  margin-top: -1.25rem;
}
.frame-parent {
  width: 40.6rem;
}
.picture-mt-9-child {
  width: 2.438rem;
  height: 2.431rem;
  position: absolute;
  margin: 0 !important;
  top: 11.1rem;
  right: 15.375rem;
  object-fit: contain;
  z-index: 1;
  filter: blur(3px);
}
.try-for-free {
  /* width: 14.313rem; */
  position: relative;
  font-size: var(--font-size-8xl);
  letter-spacing: 0.2px;
  line-height: 3.125rem;
  font-weight: 900;
  font-family: var(--font-montserrat);
  color: var(--light-text);
  text-align: center;
  display: inline-block;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25);
}
.cursor-not-allowed{
  cursor: not-allowed;
}
.button,
.text {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: var(--padding-3xs);
}
.button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-11xs) var(--padding-xl) var(--padding-11xs)
    var(--padding-11xl);
  background-color: #e84142;
  border-radius: var(--br-4981xl);
  box-shadow: var(--shadow);
  z-index: 1;
}
.frame-child1 {
  height: 9.725rem;
  width: 9.563rem;
  position: absolute;
  margin: 0 !important;
  top: -4.562rem;
  left: -8.125rem;
  object-fit: contain;
  z-index: 1;
  filter: blur(3px);
}
.button-parent,
.picture-mt-9 {
  display: flex;
  box-sizing: border-box;
  position: relative;
  max-width: 100%;
}
.button-parent {
  width: 40.6rem;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  /* padding: 0 var(--padding-2xl) 0 var(--padding-xl); */
}
.picture-mt-9 {
  flex: 1;
  border-radius: var(--br-5xl);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-28xl) var(--padding-xl) var(--padding-53xl)
    var(--padding-2xl);
  gap: 2.394rem;
  mix-blend-mode: normal;
  z-index: 1;
}
.ellipse-parent,
.page-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
}
.ellipse-parent {
  width: 81.938rem;
  justify-content: flex-start;
  position: relative;
}
.page-inner {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-23xl) var(--padding-58xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-61xl);
  color: var(--light-text);
  font-family: var(--font-montserrat);
}
@media screen and (max-width: 1200px) {
  .page-inner {
    padding-left: var(--padding-2xl);
    padding-right: var(--padding-2xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1050px) {
  .bootcamp-2024,
  .chainlink {
    align-self: center;
    font-size: 43px;
    font-weight: 600;
    /* line-height: 3.313rem; */
  }
}
@media screen and (max-width: 750px) {
  .picture-mt-9 {
    gap: 1.188rem 2.394rem;
  }
  .page-inner {
    padding-bottom: var(--padding-31xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .bootcamp-2024,
  .chainlink {
    align-self: center;
    font-size: 32px;
    font-weight: 600;
    white-space: nowrap;
    text-align: center;
    line-height: 3.188rem;
  }
  .try-for-free {
    font-size: 1rem;
    line-height: 2.5rem;
  }
  .picture-mt-9 {
    padding-top: var(--padding-12xl);
    padding-bottom: var(--padding-28xl);
    box-sizing: border-box;
  }
}
