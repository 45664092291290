.text-label-icon {
  height: 5.25rem;
  width: 6.625rem;
  position: relative;
}
.inner-vector {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-xl);
}
.inner-vector,
.text-label,
.youre-offline {
  display: flex;
  justify-content: center;
}
.youre-offline {
  width: 6.625rem;
  position: relative;
  font-weight: 600;
  align-items: center;
}
.text-label {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-xl);
}
.please-connect-to {
  align-self: stretch;
  position: relative;
  font-weight: 200;
  font-family: var(--font-inter);
}
.inner-frame-icon {
  height: 1.125rem;
  width: 1rem;
  position: relative;
  min-height: 1.125rem;
}
.retry {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 91.02%;
  font-family: var(--font-inter);
  color: var(--color-black);
  text-align: center;
}
.text-label1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs-5) 0 0;
}
.vector-arrow {
  cursor: pointer;
  border: 0;
  padding: var(--padding-9xs) var(--padding-7xs);
  background-color: var(--color-whitesmoke);
  width: 5.063rem;
  border-radius: var(--br-xl);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 0 0.75rem;
}
.parent-frame1,
.parent-frame2,
.vector-arrow {
  display: flex;
  align-items: flex-start;
}
.parent-frame2 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
}
.parent-frame1 {
  position: absolute;
  top: 6.75rem;
  left: 5.563rem;
  width: 23.125rem;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.438rem 0;
  z-index: 1;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-black);
  font-family: var(--font-lato);
}
